@import url("https://fonts.googleapis.com/css2?family=Neonderthaw&family=Noto+Serif:ital@1&display=swap");

$primary-font: "Noto Serif", serif;
$secondary-font: "Neonderthaw", cursive;

.appContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  gap: 25px;
  overflow-x: hidden;
  font-family: $primary-font;

  .card {
    width: 100vw;
    max-width: 600px;
    @media only screen and (max-width: 600px) {
      border: 0;
    }
    margin: 0 auto;

    .card-img-top {
      height: 100vw;
      max-height: 450px;
      object-fit: cover;
      object-position: center;
      @media only screen and (max-width: 600px) {
        border-radius: 0;
      }
    }

    .card-title {
      font-size: min(5.5vw, 28px);
    }
  }

  #laden {
    h2 {
      font-size: min(4.8vw, 24px);
    }
  }
}

#preisliste {
  .card-img-top {
    object-fit: cover;
    height: 300px;
  }

  h1 {
    color: #9f90cb;
    font-size: 17px;
    margin: 10px 0;
  }

  h2 {
    font-size: 13px;
    font-weight: 400;
    margin: 3px 0;
    padding-left: 10px;

    i {
      color: grey;
      float: right;
      padding-right: 10px;
    }
  }

  .alert {
    margin-top: 50px;
    font-size: 14px;
  }
}

.versandModal {
  .card {
    margin-bottom: 20px;
  }

  i {
    font-weight: bold;
  }

  .h1 {
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 300;
  }

  .h2 {
    font-size: 13px;
    margin: 0;
    font-weight: bold;
  }

  .h3 {
    font-size: 14px;
    margin: 0;
    font-weight: 300;
  }
}

#belt {
  .item {
    height: 100vw;
    max-height: 450px;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    @media only screen and (max-width: 600px) {
      border-radius: 0;
    }
  }
}
