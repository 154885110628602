@import "../../App.scss";

.homeContainer {
  height: 105vh;
  background-image: url("../../assets/header/header_xs.jpg");
  @media only screen and (min-width: 641px) {
    background-image: url("../../assets/header/header_sm.jpg");
  }
  @media only screen and (min-width: 1921px) {
    background-image: url("../../assets/header/header_md.jpg");
  }

  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-position: bottom;

  @media only screen and (min-width: 1100px) {
    background-position: center;
  }
}

.homeTitle {
  min-height: 50vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
