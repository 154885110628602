@import "../../App.scss";

.hamburger-react {
  display: flex;
  position: fixed !important;
  top: 7vw;
  right: 7vw;
  mix-blend-mode: difference;
  color: white;
  z-index: 1000;
}

.offcanvas-header {
  padding-top: 20px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logoContainer {
    padding: 0;
    flex-direction: column;
  }
}

.offcanvas-start {
  background-color: rgb(240, 240, 240);
  color: black;
  width: 75%;
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
  font-family: $primary-font;

  .linkList {
    display: flex;
    flex-direction: column;

    a {
      font-size: max(4vw, 22px);
      padding-left: 20px;
      color: black !important;
      text-decoration: none;
      cursor: pointer;
    }
  }

  a.untergruppe {
    font-size: max(3vw, 19px) !important;
    padding-left: 35px !important;
    color: rgb(116, 116, 116) !important;
  }
}
