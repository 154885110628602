@import "../../App.scss";

.logoContainer {
  width: 45%;
  padding-top: 60px;
  padding-left: 25px;
  @media only screen and (min-width: 375px) {
    padding-top: 120px;
  }
  @media only screen and (min-width: 768px) {
    padding-top: 55px;
    padding-left: 50px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 5vw;
    font-family: $primary-font;
    margin: 0;
    padding: 0;
    position: relative;
    top: 2vw;
  }

  h2 {
    font-size: 17vw;
    font-family: $secondary-font;
    margin: 0;
  }

  @media screen and (orientation: landscape) {
    width: fit-content;
    align-content: flex-start;
    justify-content: flex-start;
    padding-left: 70px;
    h1 {
      font-size: 3vw;
    }

    h2 {
      font-size: 13vw;
    }
  }
}
