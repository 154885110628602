@import "../../App.scss";

#kontakt {
  min-height: 100vh;
  background-image: url("../../assets/kontakt/leather.jpg");
  display: flex;
  background-position: bottom;
  background-size: cover;
  color: white;

  .dataBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 100%;

    .dataRow {
      display: flex;
      flex-direction: row;

      .textContent {
        h1 {
          font-size: 23px;
          @media only screen and (min-width: 768px) {
            font-size: 11px;
          }
          @media only screen and (min-width: 1024px) {
            font-size: 14px;
          }
          margin: 0;
          font-weight: 600;
          font-family: $primary-font;
        }

        h2,
        a {
          font-size: 20px;
          @media only screen and (min-width: 768px) {
            font-size: 27px;
          }
          @media only screen and (min-width: 1024px) {
            font-size: 30px;
          }
          padding-left: 5px;
          font-weight: 400;
          text-decoration: none;
          color: white;
          margin: 0;
          padding-top: 5px;
        }

        h3 {
          font-size: 17px;
          @media only screen and (min-width: 768px) {
            font-size: 15px;
          }
          @media only screen and (min-width: 1024px) {
            font-size: 17px;
          }
          margin: 0;
          padding-left: 5px;
          padding-top: 10px;
        }
      }
    }
  }
}
